import {
  TextField,
  ColumnLayout as InputColumnLayout,
} from '@adac/core-view';
import { BackofficeUser, __ } from '@adac/core-model';
import styled from 'styled-components';

const ReadOnlyField = styled(TextField)`
  background: none;
  input {
    border: none;
    background: none;
  }
`;

export const UserMetaInfo = ({
  name, username, roles, phone,
}: BackofficeUser) => (
  <InputColumnLayout ratio="repeat(3, 1fr)" marginBottom="20px">
    <ReadOnlyField
      name="name-disabled"
      value={`${name}`}
      badgeTitle={__('Name')}
      badgeEqualsPlaceholder
      disabled
      type="text"
    />
    <ReadOnlyField
      name="email-disabled"
      value={`${username}`}
      badgeTitle={__('Email')}
      badgeEqualsPlaceholder
      disabled
      type="text"
    />
    <ReadOnlyField
      name="roles-disabled"
      value={`${JSON.stringify(roles)}`}
      badgeTitle={__('Roles')}
      badgeEqualsPlaceholder
      disabled
      type="text"
    />
    <ReadOnlyField
      name="roles-disabled"
      value={`${phone}`}
      badgeTitle={__('mfaPhone')}
      badgeEqualsPlaceholder
      disabled
      type="text"
    />
  </InputColumnLayout>
);
