import { Button as MuiButton } from '@material-ui/core';
import {
  getApiRoutes, __,
} from '@adac/core-model';
import DriverIcon from '@material-ui/icons/DriveEta';

export const CompanyDriverListButton = (props: any) => {
  const { record, history: { push } } = props;
  return (<MuiButton onClick={() => push(getApiRoutes().companyAdmin.editDriversList(record.id))}><DriverIcon />{__('List Drivers')}</MuiButton>);
};
