import { v4 as uuidv4 } from 'uuid';
export function capitalize(str) {
    if (!str)
        return '';
    return str[0].toUpperCase() + str.slice(1);
}
export const lcfirst = (string) => String(string).charAt(0).toLowerCase() + String(string).slice(1);
export const ucfirst = (string) => String(string).charAt(0).toUpperCase() + String(string).slice(1);
export const camelize = (string, change1st = false) => (String(string)
    .replace(/[+./_]/, '-')
    .split('-')
    .map((part, index) => ((index || change1st) && ucfirst(part)) || part)
    .join(''));
export const formatLocaleTimestamp = (dateInput) => (dateInput ? `${new Date(dateInput).toLocaleDateString('DE')} ${new Date(dateInput).toTimeString().slice(0, 5)} Uhr` : '-');
export const formatDate = (value) => { var _a, _b; return ((_a = new Date(`${value}`)) === null || _a === void 0 ? void 0 : _a.toLocaleDateString('DE')) || ((_b = `${value}`) === null || _b === void 0 ? void 0 : _b.substr(0, 10)) || ''; };
export const parseDate = (date) => {
    const parsed = Date.parse(date);
    if (!isNaN(parsed)) {
        return new Date(parsed);
    }
    const arr = date.split(/[- :]/);
    const constructed = new Date(+arr[0], +arr[1] - 1, +arr[2], +arr[3], +arr[4], +arr[5]).valueOf();
    if (!isNaN(constructed)) {
        return new Date(constructed);
    }
    throw new Error(`Date cannot be parsed! ${date}`);
};
const stripTag = (text, tag) => {
    const re = new RegExp(`<${tag}[^>]*>.*</${tag}[^>]*>`, 'gi');
    return text.replace(re, '');
};
export const stripHTML = (text, eliminateTags = ['h', 'footer']) => eliminateTags
    .reduce((t, tag) => stripTag(t, tag), (text || '').replace(/\n/gi, '[br]'))
    .replace(/\[br\]/gi, '<br>')
    .replace(/<br[^>]*>/gi, '\n')
    .replace(/<[^>]+>/gi, '');
export const adjustFuzzyChars = (str) => str
    .replace(/[eéèë]/gi, 'e')
    .replace(/[aáâàä]/gi, 'a')
    .replace(/[òôoöóőõ]/gi, 'o')
    .replace(/[iíî]/gi, 'i')
    .replace(/[uûüűũ]/gi, 'u')
    .replace(/[^A-Za-z0-9]/gi, '\\W')
    .replace(/[e]/gi, '[eéèë]')
    .replace(/[a]/gi, '[aáâàä]')
    .replace(/[o]/gi, '[òôoöóőõ]')
    .replace(/[i]/gi, '[iíî]')
    .replace(/[u]/gi, '[uûüűũ]');
export const replaceVowelsToEnglishOnes = (str) => str
    .replace(/[EÉÈË]/g, 'E')
    .replace(/[AÁÂÀÄ]/g, 'A')
    .replace(/[ÒÔOÖÓŐÕ]/g, 'O')
    .replace(/[IÍÎ]/g, 'I')
    .replace(/[UÚÛÜŰŨ]/g, 'U')
    .replace(/[eéèë]/g, 'e')
    .replace(/[aáâàä]/g, 'a')
    .replace(/[òôoöóőõ]/g, 'o')
    .replace(/[iíî]/g, 'i')
    .replace(/[uúûüűũ]/g, 'u');
export const removeNonEnglishCharacters = (str, keep = '') => str.replace(new RegExp(`[^a-zA-Z._0-9${keep}\\-]`, 'g'), '');
export const isoDatePattern = /^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(\.[0-9]+)?(Z|[+-](?:2[0-3]|[01][0-9]):[0-5][0-9])?$/;
export const makeArrayPattern = (items, options) => {
    const s = (options === null || options === void 0 ? void 0 : options.separator) || '\\s*,\\s*';
    const ib = (options === null || options === void 0 ? void 0 : options.itemBoundaries) || ['"', '"'];
    const ab = (options === null || options === void 0 ? void 0 : options.arrayBoundaries) || ['\\[', '\\]'];
    return new RegExp(`^${ab[0]}(${ib[0]}${items}${ib[1]})(${s}${ib[0]}${items}${ib[1]})*${ab[1]}$`, 'i');
};
export const zipListPattern = makeArrayPattern('\\d{4,5}', { itemBoundaries: ['', ''], arrayBoundaries: ['', ''] });
export const logLevelPattern = makeArrayPattern('(error|warn|info|debug|verbose|silly)');
export const hostnameArrayPattern = makeArrayPattern('[\\w-]+(\\.[\\w-]+)+');
export const urlPattern = /^https?:\/\/[\w-@]+(\.[\w-]+)+[/\w-.]+$/i;
export const cronPattern = /^(((\d+,)+\d+|(\d+(\/|-)\d+)|\d+|\*) ?){5,7}$/i;
export const base64ImageRegex = /^data:image\/(png|jpg|jpeg|gif|bmp|svg)(?:;charset=utf-8)?;base64,(?:[A-Za-z0-9]|[+/])+={0,2}/;
export const bufferToString = (buffer) => (Buffer.isBuffer(buffer) ? Buffer.from(buffer).toString() : buffer);
export const stripSpecialCharacters = (input) => removeNonEnglishCharacters(replaceVowelsToEnglishOnes(input), ' !?,');
export const getNewUUID = () => uuidv4();
