export function getFirstErrorMessage(error) {
    return (error
        && error.data
        && ((Array.isArray(error.data)
            && error.data[0]
            && (error.data[0].error
                || Object.values(error.data[0])[0])) || (`${Object.keys(error.data)[0]}: ${Object.values(error.data)[0]}`))) || '';
}
export function getErrorText(error) {
    return getFirstErrorMessage(error);
}
export class NetworkError extends Error {
    get getText() {
        const matches = `${this.stack}`.match(/^(.*)\n/gi);
        return (matches && matches[0]);
    }
    constructor(error) {
        super(getErrorText(error));
        this.status = (error && error.status) || 500;
    }
}
