/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useLayoutEffect, useState } from 'react';
import {
  Edit, getResources, DELETE, UPDATE, showNotification as showNotificationAction,
} from 'react-admin';

import {
  Formik, FormikHelpers as FormikActions,
  useFormikContext,
} from 'formik';

import { connect } from 'react-redux';

import {
  getApiRoutes, UserRoleListBackoffice, __, AvailableNotificationSettingValues as Channel,
  AuthenticationMode,
} from '@adac/core-model';

import { LoadingSpinner, NetworkLoader } from '@adac/core-view';
import dataProvider from '../../dataProvider';

import { USERS_BACKOFFICE } from '../../dataProvider/resources';
import { UserForm } from './UserForm';

import { UserFormValues } from './UserFormValues';
import {
  DeleteButton,
} from '../common/DeleteButton';
import { UserFormSchema } from './UserFormSchema';
import { FormProps } from '../common/react-admin/interfaces';
import { useSettings } from '../../hooks/useSettings';

const UserEditForm = (raProps: FormProps) => {
  const user = raProps.record;

  const onSubmit = async (values: UserFormValues, actions: FormikActions<UserFormValues>) => {
    const editedUser = {
      id: values.id,
      name: values.name,
      username: values.username,
      role: values.role,
      ...(values.password ? { password: values.password, confirmPassword: values.confirmPassword } : {}),
      settings: {
        [AuthenticationMode.SIGN_IN_CHANNEL]: values.channel,
      },
      phone: values.channel === Channel.SMS ? values.address : undefined,
    };
    try {
      await dataProvider(UPDATE, raProps.resource, { id: user.id, data: editedUser });
      actions.setSubmitting(false);
      raProps.history.push(getApiRoutes(false, '').backoffice.users.list);
    } catch (error) {
      raProps.showNotification(__(error?.toString() ?? 'Error'), 'warning');
      console.log(error);
      actions.setSubmitting(false);
    }
  };

  const initialValues = {
    ...user,
    password: '',
    confirmPassword: '',
    channel: Channel.SMS,
    address: '',
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={UserFormSchema}
      onSubmit={onSubmit}
    >
      <UserEditFormProvider {...raProps} />
    </Formik>
  );
};

function UserEditFormProvider(raProps: FormProps) {
  const [roles] = useState(UserRoleListBackoffice);
  const { settings, isLoading } = useSettings(raProps.record.id);
  const {
    values: { channel }, isSubmitting, setSubmitting, setFieldValue,
  } = useFormikContext<UserFormValues>();

  const user = raProps.record;
  const { phone, username } = user;

  const deleteUser = async () => {
    setSubmitting(true);
    try {
      await dataProvider(DELETE, USERS_BACKOFFICE, { id: user.id });
      raProps.history.push(getApiRoutes(false, '').backoffice.users.list);
    } catch (error) {
      raProps.showNotification(__(error?.toString() ?? 'Error'), 'warning');
    } finally {
      setSubmitting(false);
    }
  };

  useLayoutEffect(() => {
    if (channel === Channel.SMS) {
      setFieldValue('address', phone || '');
    }
    if (channel === Channel.EMAIL) {
      setFieldValue('address', username);
    }
  }, [channel, setFieldValue, phone, username]);

  useEffect(() => {
    setFieldValue('channel', settings[AuthenticationMode.SIGN_IN_CHANNEL]);
  }, [settings, setFieldValue]);

  return (
    <NetworkLoader
      loading={isLoading}
      loadingView={<LoadingSpinner>{__('Loading')}</LoadingSpinner>}
    >
      <UserForm
        user={user}
        roles={roles}
        onCancel={() => raProps.history.push(getApiRoutes(false, '').backoffice.users.list)}
      >
        <DeleteButton
          isLoading={isSubmitting}
          disabled={isSubmitting}
          onClick={deleteUser}
        />
      </UserForm>
    </NetworkLoader>
  );
}

const mapEditStateToProps = (state: any) => ({
  resources: getResources(state),
});

const ConnectedUserEditForm = connect(mapEditStateToProps, {
  showNotification: showNotificationAction,
})(UserEditForm);

export default (raProps: any) => (
  <Edit {...raProps}>
    <ConnectedUserEditForm {...raProps} />
  </Edit>
);
