/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Filter,
  TextInput,
  BooleanInput,
} from 'react-admin';
import {
  __,
} from '@adac/core-model';

export default (props: any) => (
  <Filter {...props}>
    <BooleanInput label={__('Hide inactive')} source="futureHoliday" alwaysOn />
    <TextInput label={__('Year')} source="year" allowEmpty />
  </Filter>
);
