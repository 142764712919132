/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import styled from 'styled-components';
import { showNotification as showNotificationAction } from 'react-admin';
import { connect } from 'react-redux';

import {
  View, ButtonTitle, Title, SimpleCheckMark, RadioGroup, RadioGroupStyles,
} from '@adac/core-view';
import { __ } from '@adac/core-model';

import { getFullReportCSVUrl } from '../../dataProvider/statistics';
import { PreviousFullReportList } from './PreviousFullReportList';
import { DateFilterForm, DateFilterProps } from './FilterForm';
import {
  ConnectedDownloadReport,
} from './DownloadReportButton';

const StatsTitle = styled(Title)`
  margin-top: 50px;
  margin-bottom: 32px;
`;

const StatisticsPageLayoutStyles = styled(View)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: flex-start;
`;

const getCurrentMonth = () => {
  const date = new Date(Date.now());
  const y = date.getFullYear();
  const m = date.getMonth();
  const first = new Date(y, m, 1);
  const last = new Date(y, m + 1, 1);
  return [first, last] as const;
};

const DensedRadioGroup = styled(RadioGroup)`
${RadioGroupStyles} {
  grid-template-columns: repeat(2,max-content);
}
`;

const StatisticsPage = () => {
  const [[from, to], setPeriod] = React.useState(getCurrentMonth);
  const [format, setformat] = React.useState('bi');
  // const generateEstimation = React.useCallback(async () => getEstimationsCSVUrl(), []);
  const generateFullreport = React.useCallback(async () => getFullReportCSVUrl([from.toISOString(), to.toISOString(), format]), [from, format, to]);

  return (
    <StatisticsPageLayoutStyles>
      <View>
        {/* <StatsTitle>{__('Estimation reporting')}</StatsTitle>
        <ConnectedDownloadReport action={generateEstimation}>
          <ButtonTitle>{__('Download estimations')}</ButtonTitle>
        </ConnectedDownloadReport> */}

        <StatsTitle>{__('Database reporting')}</StatsTitle>
        <ConnectedDownloadReport action={generateFullreport}>
          <ButtonTitle>{__('Download full database')}</ButtonTitle>
        </ConnectedDownloadReport>

        <DateFilterForm
          from={from}
          to={to}
          onChange={(values: DateFilterProps) => setPeriod([values.from || from, values.to || to])}
        />

        <DensedRadioGroup
          renderItem={SimpleCheckMark}
          items={[
            {
              id: 'bi', title: __('Optimized for BI'), value: 'bi',
            },
            {
              id: 'human', title: __('Optimized for Human'), value: 'human',
            },
          ]}
          name="OptimizedForBI"
          onChange={setformat}
          value={format}
          cols={2}
        />

      </View>

      <View>
        <StatsTitle>{__('Report history')}</StatsTitle>
        <PreviousFullReportList />
      </View>
    </StatisticsPageLayoutStyles>
  );
};

export default connect(null, {
  showNotification: showNotificationAction,
})(StatisticsPage);
