import {
  __,
} from '@adac/core-model';
import {
  Title, Text, ErrorMessage, themeConfig, useLocation,
  ErrorConfirmFunc, ConfirmTokenForm, useFormikAuth, NewPasswordForm, NewPasswordFormValues,
  useTokenMatcher, ForgotPasswordStart,
  LoginHeader,
  LocalizableError,
  resetPasswordStart, confirmToken, resetPasswordCommit,
} from '@adac/core-view';
import {
  FormikHelpers as FormikActions,
} from 'formik';
import { useEffect, useState } from 'react';
import { LayoutStyles } from './Login';

const SuccessMessage = ErrorMessage;

function ForgotPassword() {
  const [overlay, setOverlay] = useState<string | JSX.Element>('');
  const setTimedOverlay = setOverlay;
  const [username, setUserName] = useState<string>('');
  const { goTo } = useLocation();

  const {
    tokenKey, tokenValue, setTokenKey, setTokenValue, state,
  } = useTokenMatcher();

  const onError: ErrorConfirmFunc = (error: LocalizableError) => {
    if (error.message.match(/(Too many attempts)|(expired)/)) setTokenKey('');
    setOverlay(<ErrorMessage>{__(error.toLocalizedString ? error.toLocalizedString() : error.toString())}</ErrorMessage>);
  };

  const { onSubmit: onStartSubmit, result: tokenKeyResult } = useFormikAuth((username: string) => { setUserName(username); return resetPasswordStart(username); }, onError);

  useEffect(() => {
    if (tokenKeyResult) {
      setTokenKey(tokenKeyResult);
    }
  }, [tokenKeyResult, setTokenKey, setTimedOverlay]);

  const { onSubmit: onConfirmSubmit } = useFormikAuth(confirmToken, onError, (token) => setTokenValue(token));

  const onPasswordCommitSubmit = async (values: NewPasswordFormValues, actions: FormikActions<NewPasswordFormValues>) => {
    try {
      await resetPasswordCommit(values.newPassword, values.confirmPassword, tokenKey, tokenValue);
      setOverlay(<SuccessMessage icon="info" color={themeConfig.colors.progress}>{__('Password reset performed')}</SuccessMessage>);
      goTo('/', { prefixed: false });
    } catch (error) {
      setOverlay(<ErrorMessage>{__((error as Error)?.message)}</ErrorMessage>);
    } finally {
      actions.setSubmitting(false);
    }
  };

  if (state === 'validated') {
    return (
      <LayoutStyles padding="24px">
        <LoginHeader marginLeft="-24px" marginTop="-24px" marginRight="-24px" />
        {overlay}
        <NewPasswordForm onSubmit={onPasswordCommitSubmit} />
      </LayoutStyles>
    );
  }

  if (state === 'validating') {
    return (
      <LayoutStyles padding="24px">
        <LoginHeader marginLeft="-24px" marginTop="-24px" marginRight="-24px" />
        <ConfirmTokenForm tokenKey={tokenKey} onSubmit={onConfirmSubmit} onCancel={() => { setTokenKey(''); goTo('/', { prefixed: false }); }}>
          <Title>
            {__('verificationMessageStart')}
            {username}
            {__('verificationMessageEnd')}
          </Title>

          <br />
          <br />
          <Text>{__('Please enter confirmation token value')}</Text>
          {overlay}
        </ConfirmTokenForm>
      </LayoutStyles>
    );
  }

  return (
    <LayoutStyles padding="24px">
      <LoginHeader marginLeft="-24px" marginTop="-24px" marginRight="-24px" />
      {overlay}
      <ForgotPasswordStart onSubmit={onStartSubmit} onCancel={() => goTo('/', { prefixed: false })} onChange={() => setOverlay('')} />
    </LayoutStyles>
  );
}

export default ForgotPassword;
