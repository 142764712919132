import { isInvoiceUploadAllowed } from '../adac-states/case-event-data.js';
import { CaseStatusList, } from '../adac-states/case-events.js';
import { parseDate } from '../helpers/string-helpers.js';
import { CompanyTypes } from '../types/Company.js';
const { INVOICE_UPLOAD_MISSING_AGE_IN_DAYS, REACT_APP_INVOICE_UPLOAD_MODULE_LAUNCH_DATE, INVOICE_UPDATE_CORRECT_THRESHOLD_MINUTES, INVOICE_JOB_SCHEDULE: invoiceJobSchedule, INVOICE_UPLOAD_FILETYPES: invoiceUploadFiletypes, INVOICE_UPLOAD_REMINDER_JOB_SCHEDULE: invoiceUploadReminderJobSchedule, } = process.env;
const invoiceUploadMissingAgeInDays = parseInt(INVOICE_UPLOAD_MISSING_AGE_IN_DAYS !== null && INVOICE_UPLOAD_MISSING_AGE_IN_DAYS !== void 0 ? INVOICE_UPLOAD_MISSING_AGE_IN_DAYS : '', 10);
const invoiceUpdateCorrectThresholdMinutes = parseInt(INVOICE_UPDATE_CORRECT_THRESHOLD_MINUTES !== null && INVOICE_UPDATE_CORRECT_THRESHOLD_MINUTES !== void 0 ? INVOICE_UPDATE_CORRECT_THRESHOLD_MINUTES : '', 10);
const invoiceUploadMandatoryBaseDate = REACT_APP_INVOICE_UPLOAD_MODULE_LAUNCH_DATE ? parseDate(REACT_APP_INVOICE_UPLOAD_MODULE_LAUNCH_DATE) : parseDate('2022-05-18T09:00:00+02:00');
const isActive = (compare = `${new Date(Date.now())}`) => invoiceUploadMandatoryBaseDate.getTime() <= parseDate(compare).getTime();
export default {
    INVOICE_UPLOAD_MISSING_AGE_IN_DAYS: Number.isNaN(invoiceUploadMissingAgeInDays) ? 14 : invoiceUploadMissingAgeInDays,
    INVOICE_UPDATE_CORRECT_THRESHOLD_MINUTES: Number.isNaN(invoiceUpdateCorrectThresholdMinutes) ? 30 : invoiceUpdateCorrectThresholdMinutes,
    REACT_APP_INVOICE_UPLOAD_MODULE_LAUNCH_DATE: invoiceUploadMandatoryBaseDate,
    INVOICE_JOB_SCHEDULE: invoiceJobSchedule || '0 */5 * * * *',
    INVOICE_UPLOAD_REMINDER_JOB_SCHEDULE: invoiceUploadReminderJobSchedule || '0 0 16 * * 5',
    INVOICE_UPLOAD_FILETYPES: invoiceUploadFiletypes || 'application/pdf, image/jpg , image/jpeg',
    isActive,
    isInvoiceUploadAllowed: ({ event, data, case: caseRecord }, companyType) => (event === CaseStatusList.CASE_COMPLETED
        && caseRecord && isActive(`${caseRecord.createdAt}`)
        && companyType === CompanyTypes.EXT
        && isInvoiceUploadAllowed(data)),
};
