/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';
import {
  Datagrid, DatagridBody, List, TextField, showNotification as showNotificationAction, refreshView as refreshViewAction,
} from 'react-admin';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import {
  useSocketClient,
} from '@adac/core-view';
import { __, getAnyCaseStatusEventName } from '@adac/core-model';
import setClosingCaseAction, { CaseCloseType } from '../../actions/setClosingCase';

import saveUntakenCases from '../../actions/saveUntakenCases';

import CaseFilter from './OpenCaseFilter';
import { CompanyListStyles } from '../snd/CompanyList';

const OpenCasesDatagridRow = ({
  record, resource, id, children, basePath, history,
}: any) => {
  const rowHeight = 60;
  const openCasePage = (caseId: string) => {
    history.push(`/case-status/${caseId}`);
  };

  return (
    <TableRow
      key={id}
      style={{
        height: `${rowHeight}px`,
        cursor: 'pointer',
      }}
      hover
    >
      {/* data columns based on children */}
      {React.Children.map(children, field => (
        <TableCell
          key={`${id}-${field.props.source}`}
          onClick={() => openCasePage(record.token)}
          style={{ padding: '8px', whiteSpace: 'pre-wrap', textAlign: 'center' }}
        >
          {React.cloneElement(field, {
            record,
            basePath,
            resource,
          })}
        </TableCell>
      ))}

    </TableRow>
  );
};

const mapStateToProps = (state: any) => ({
  untakenCases: state.untakenCases,
});

const ConnectedOpenCasesDatagridRow = connect(mapStateToProps, {
  showNotification: showNotificationAction,
  setClosingCase: setClosingCaseAction,
})(withRouter(OpenCasesDatagridRow));

const OpenCasesDatagridBody = (props: any) => (
  <DatagridBody
    {...props}
    row={(
      <ConnectedOpenCasesDatagridRow />
    )}
  />
);
const OpenCasesDatagrid = (props: any) => <Datagrid {...props} body={<OpenCasesDatagridBody />} />;

interface OpenCaseListProps {
  saveCases: (p: any) => void;
  refreshView: () => void;
  // react-admin List:
  closingCase: CaseCloseType;
  [key: string]: any;
}

const OpenCaseList = ({
  classes, refreshView, cancellingCase, closingCase, ...props
}: OpenCaseListProps) => {
  const socket = useSocketClient();

  // new socket based notification and refresh system
  React.useEffect(() => {
    socket.on(getAnyCaseStatusEventName(), () => refreshView());
    return () => {
      socket.off(getAnyCaseStatusEventName());
    };
  }, [socket, refreshView]);

  // after a case was either cancelled or closed, refresh the list
  useEffect(() => {
    if (cancellingCase === false || closingCase === false) {
      refreshView();
    }
  }, [cancellingCase, closingCase, refreshView]);

  return (
    <CompanyListStyles>
      <List
        {...props}
        bulkActionButtons={false}
        // actions={null}
        filters={<CaseFilter sndCompany />}
      >
        <OpenCasesDatagrid classes={classes}>
          <TextField source="id" label={__('Case-Id')} /* sortable={false} */ />
          <TextField source="commissioningReference" label={__('CommissioningReference')} sortable={false} />
          <TextField source="customerName" label={__('Name of customer')} /* sortable={false} */ />
          <TextField source="customerPhone" label={__('Customer Phone')} /* sortable={false} */ />
          <TextField source="productType" label={__('ProductType')} />
          <TextField source="customerAddress" label={__('Customer Address')} /* sortable={false} */ />
          <TextField source="customerZipCode" label={__('Zip Code')} /* sortable={false} */ />
          <TextField source="companyName" label={__('SND Name')} sortable={false} />
          <TextField source="timeOfOrder" label={__('Time of Order')} /* sortable={false} */ />
          <TextField source="complexity" label={__('DoorComplexity')} /* sortable={false} */ />
          <TextField source="currentStatusText" label={__('Current Status')} sortable={false} />
          <TextField source="currentStatusTime" label={__('Time of current Status')} sortable={false} />
          {/* <TextField source="driverId" label={__('Driver ID')} sortable={false} /> */}
          {/* <TextField source="driverName" label={__('Leistungserbringer')} sortable={false} /> */}
        </OpenCasesDatagrid>
      </List>
    </CompanyListStyles>
  );
};

const mapStateToPropsOpenCaseList = (state: any) => ({
  closingCase: state.closingCase,
  cancellingCase: state.cancellingCase,
});

export default (connect(mapStateToPropsOpenCaseList, {
  saveCases: saveUntakenCases,
  refreshView: refreshViewAction,
})(OpenCaseList));
