/* eslint-disable @typescript-eslint/no-explicit-any */
import { EscalationData } from '@adac/core-model';
import { SAVE_MANUAL_CASE } from '../actions/saveManualCase';

export const SAVE_MANUAL_CASE_ASYNC = 'SAVE_MANUAL_CASE_ASYNC';

// eslint-disable-next-line default-param-last
export default (previousState = null, { type, payload }: { type: string; payload: EscalationData }) => {
  if (type === SAVE_MANUAL_CASE) {
    return payload;
  }
  return previousState;
};
