import React from 'react';
import styled from 'styled-components';
import { string, object, number } from 'yup';
import { showNotification as showNotificationAction } from 'react-admin';
import { connect } from 'react-redux';

import { __ } from '@adac/core-model';

import {
  CaseStatusLayoutStyles, CommentPlaceholder, GeneralButton, Loader,
} from '@adac/core-view';
import CaseStatusCommentItem, { CaseStatusCommentItemWrapper, CaseStatusCommentItemProps } from './CaseStatusCommentItem';

import { createCaseComment } from '../../../dataProvider/createCaseComment';

import StoresContext from '../../../stores';

import { useUserRole } from '../../../helpers/useUserRole';

export const CaseStatusCommentSchema = object().shape({
  userId: number()
    .required(),
  caseId: number()
    .required(),
  name: string()
    .nullable(),
  text: string()
    .required(),
});

const CaseStatusCommentForm = styled.form`
  width: 100%;
`;

const CaseStatusCommentTextarea = styled.textarea`
  width: 100%;
  margin-top: 8px;
  border-radius: 4px;
  border: solid 1px #b2b2b2;
  background-color: #ffffff;
  height: 96px;
  resize: none;
  padding: 16px 0 0 16px;
`;

const CaseStatusButtonContainer = styled.div`
  padding-top: 16px;
  max-width: 164px;
  margin-left: auto;
`;

interface CaseStatusCommentProps {
  isLoading: boolean;
  items?: CaseStatusCommentItemProps[];
  caseId?: number;
  caseToken?: string;
  refreshCaseData: () => void;
  showNotification: (errorMsg: string) => void;
}

const Fallback = () => (
  <CaseStatusCommentItemWrapper style={{ height: '120px' }}>
    <CommentPlaceholder bgColor="#b2b2b2" fgColor="#f3f3f3" />
  </CaseStatusCommentItemWrapper>
);

const CaseStatusComment = ({
  isLoading,
  items,
  caseId,
  caseToken,
  refreshCaseData,
  showNotification,
}: CaseStatusCommentProps): JSX.Element => {
  const [comment, setComment] = React.useState('');
  const { auth: authStore } = React.useContext(StoresContext);
  const userRole = useUserRole();
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  // TODO: we shall use Composable*Store instead
  const onSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();

    setIsSubmitting(true);

    try {
      if (!caseToken) throw new Error('no caseToken');
      if (!caseId) throw new Error('no caseId');

      const data = {
        text: comment,
        userId: authStore.userId,
        caseId,
      };

      await CaseStatusCommentSchema.validate(data);

      await createCaseComment(caseToken, data);

      refreshCaseData();

      setComment('');
    } catch (err) {
      console.error((err as Error)?.message);
      showNotification((err as Error)?.toString());
      return;
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <CaseStatusLayoutStyles>
      {items?.map((item, idx) => (
        <CaseStatusCommentItem
          {...item}
          key={`${item.name}_${idx}`}
        >
          {item.text}
        </CaseStatusCommentItem>
      ))}

      <Loader
        isLoading={isLoading || isSubmitting}
        component={Fallback}
      />

      {!userRole.readonly && (
        <CaseStatusCommentForm onSubmit={onSubmit}>
          <CaseStatusCommentTextarea
            value={comment}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setComment(e.target.value)}
            placeholder={__('Write comment')}
          />
          <CaseStatusButtonContainer>
            <GeneralButton title={__('Send')} type="submit" cta disabled={comment.length < 10 || isSubmitting} isLoading={isSubmitting} />
          </CaseStatusButtonContainer>
        </CaseStatusCommentForm>
      )}
    </CaseStatusLayoutStyles>
  );
};

const actionsToMapToProps = {
  showNotification: showNotificationAction,
};

export default connect(null, actionsToMapToProps)(CaseStatusComment);
