export const isInvoiceUploadAllowed = (data) => !!(data === null || data === void 0 ? void 0 : data.allowInvoiceUpload);
export function isSndOfferAccepted(data) {
    const dataSndOfferAccepted = data;
    return !!(data && dataSndOfferAccepted.companyId && dataSndOfferAccepted.driverId && dataSndOfferAccepted.estimation);
}
export function isSndOfferRejected(data) {
    const dataSndOfferAccepted = data;
    return !!(data && dataSndOfferAccepted.companyId);
}
export function isJobCancelation(data) {
    const dataSndOfferAccepted = data;
    return !!(data && dataSndOfferAccepted.reason);
}
export function isMessageResultData(data) {
    const messageResultData = data;
    return !!(data && messageResultData.message);
}
export function hasSignatureOrPhotos(data) {
    return !!(data && (data.signature || data.photos));
}
export function hasSignature(data) {
    return !!(data && data.signature);
}
export function isJobCommissioned(data) {
    const jobCommissionedData = data;
    return !!(data && jobCommissionedData.photos && jobCommissionedData.signature);
}
export function isFinalReportEvidence(data) {
    const finalReportData = data;
    return !!(data && finalReportData.receiveByEmail);
}
export function isCaseStatusDataType(data) {
    const actionData = data;
    return !!(data && (actionData.address
        || actionData.filename
        || (actionData.longitude || actionData.latitude || actionData.companyId)
        || actionData.sms
        || actionData.signature
        || actionData.additionalCosts
        || actionData.userId
        || actionData.rejectedAt
        || (actionData.commissionReport || actionData.commissionExtraDamage || actionData.finalStatusReport || actionData.triggerEventLogId)
        || actionData.photos
        || (actionData.companyId || actionData.driverId || actionData.estimation)
        || actionData.customerPhone));
}
export function isCaseStatusDataString(data) {
    return !!data && typeof data === 'string';
}
export function isReportGeneration(data) {
    return !!data && (!!data.commissionReport
        || !!data.commissionExtraDamage
        || !!data.finalStatusReport);
}
export function hasAnyFinalReportValue(finalStatusReport) {
    return !!(finalStatusReport === null || finalStatusReport === void 0 ? void 0 : finalStatusReport.customer) || !!(finalStatusReport === null || finalStatusReport === void 0 ? void 0 : finalStatusReport.billing) || !!(finalStatusReport === null || finalStatusReport === void 0 ? void 0 : finalStatusReport.customer);
}
export function isNewReportGeneration(data) {
    return !!isReportGeneration(data) && hasAnyFinalReportValue(data.finalStatusReport);
}
export function isLegacyReportGeneration(data) {
    return !!isReportGeneration(data) && (typeof data.finalStatusReport === 'string');
}
