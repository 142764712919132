import { __ } from '@adac/core-model';
import { Button as MuiButton } from '@material-ui/core';
import VisibilityRoundedIcon from '@material-ui/icons/VisibilityRounded';
import { UserBlockListProps } from './UserBlockList';

export const UserBlockAdminLogsButton = ({
  record, history: { push },
}: UserBlockListProps) => {
  const url = `/admin/backoffice/adminlog?filter=%7B"entity"%3A"User"%2C"status"%3A"401"%2C"entityId"%3A"${record.id}"%7D&order=DESC&page=1&perPage=10&sort=id`;

  async function onCheckBlockLogs() {
    push(url);
  }

  return (
    <MuiButton onClick={() => onCheckBlockLogs()}>
      <VisibilityRoundedIcon />
      {__('Show All')}
    </MuiButton>
  );
};
