/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Filter,
  TextInput,
  ArrayInput,
  DateInput,
} from 'react-admin';
import {
  __,
} from '@adac/core-model';
import { FlexView } from '@adac/core-view';

export default (props: any) => {
  const hideAndClearFilter = async (a: any) => {
    if (a==='createdAt') {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { createdAtFrom, createdAtTo, ...restFilters } = props.filterValues;
      await props.setFilters(restFilters);
      setTimeout(() => props.hideFilter(a), 1000);
    } else props.hideFilter(a);
  };
  return (
    <Filter {...props} hideFilter={hideAndClearFilter}>
      <TextInput source="entity" label={__('Entity')} alwaysOn />
      <TextInput source="entityId" label={__('Entity Id')} />
      <TextInput source="action" label={__('Action')} />
      <TextInput source="userId" label={__('User ID')} />
      <TextInput source="status" label={__('Status')} alwaysOn />
      <TextInput source="url" label={__('Url')} alwaysOn />
      <TextInput source="data" label={__('Request Data')} alwaysOn />
      <TextInput source="response" label={__('Response Data')} />
      <ArrayInput source="createdAt" label={__('createdAt')} hideFilter={(a: any) => console.log('hideFilter', a)}>
        <FlexView>
          <DateInput source="createdAtFrom" />
          <DateInput source="createdAtTo" />
        </FlexView>
      </ArrayInput>
    </Filter>
  );
};
