import { __ } from '@adac/core-model';
import {
  FragmentProps, ChildrenProps, CaseStatusLayoutStyles, CaseStatusTitle,
} from '@adac/core-view';

interface CaseStatusSectionProps extends ChildrenProps, FragmentProps {
  title?: string;
}

const CaseStatusSection = ({ title, children, ...props }: CaseStatusSectionProps) => (
  <CaseStatusLayoutStyles {...props}>
    {title && (
      <CaseStatusTitle area="title">
        {__(title)}
      </CaseStatusTitle>
    )}
    {children}
  </CaseStatusLayoutStyles>
);

export default CaseStatusSection;
