import { __, User } from '@adac/core-model';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { Button as MuiButton } from '@material-ui/core';
import { connect } from 'react-redux';
import { unblockUser } from '../../networking/unblock';
import { useAuthStore } from '../../stores';
import { UserBlockListProps } from './UserBlockList';
import { setBlockedUsersAction, useBlockedUsersAction } from '../../actions/setBlockedUsers';

interface UnblockButtonProps extends UserBlockListProps {
  setBlockedUsers: (users: User[]) => void;
}

export const UnblockButton = ({
  record, showNotification, refreshView, setBlockedUsers,
}: UnblockButtonProps) => {
  const { token } = useAuthStore();
  const { get: getAndSetBlockedUsers } = useBlockedUsersAction(setBlockedUsers);

  async function unblock() {
    try {
      await unblockUser(record.id, token);
      await getAndSetBlockedUsers();
      showNotification?.(__('User unblock success'));
      refreshView();
    } catch (error) {
      showNotification?.(__('User unblock error'));
    }
  }

  async function onUnblock() {
    // eslint-disable-next-line no-alert
    if (!window.confirm(__('Are you sure?'))) return;
    await unblock();
  }

  return (
    <MuiButton onClick={() => onUnblock()}>
      <LockOpenIcon />
      {__('Unblock')}
    </MuiButton>
  );
};

export const UserUnblockButton = connect(null, {
  setBlockedUsers: setBlockedUsersAction,
})(UnblockButton);
