/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { getResources, showNotification as showNotificationAction } from 'react-admin';
import { connect } from 'react-redux';

import { RouteComponentProps, withRouter } from 'react-router';
import {
  useMyNotificationSettings,
  Button, NotificationSettingsForm, NotificationSettingsFormSchema, useCompanyById, View, ErrorMessage, ColumnLayout,
  saveNotificationSettings,
} from '@adac/core-view';
import {
  getApiRoutes,
  NotificationSettingsFormValues, __,
  defaultNotificationSettingValues,
  CompanyTypes,
  UserRole,
  hasUserRole,
  getAllNotificationSettingValues,
  activeNotificationSettingsWithTwilio,
} from '@adac/core-model';
import {
  Form, Formik, FormikHelpers as FormikActions, FormikProps,
} from 'formik';
import styled from 'styled-components';
import Header from '../common/Header';
import CardContent from './CardContent';
import { RAShowNotificationProps } from '../common/react-admin/interfaces';
import StoresContext from '../../stores/index';
import { Card } from '../cases/CaseStatus';
import { useUserRole } from '../../helpers/useUserRole';

const ConfirmButtons = styled(View)`
  margin-top: 10px;
  display:grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 10px;
`;

interface NotificationSettingsEdit extends RAShowNotificationProps, RouteComponentProps<any> {
}

const NotificationSettingsEdit = (raProps: NotificationSettingsEdit) => {
  const stores = React.useContext(StoresContext);
  const companyId = raProps.match.params.companyId;
  const { company } = useCompanyById(companyId);
  const requestUrl = getApiRoutes().companyAdmin.getNotificationSettings(companyId);
  const settings = useMyNotificationSettings(stores.auth.token, requestUrl);

  const userRole = useUserRole();
  const isAllowedToEdit = hasUserRole(userRole.names, UserRole.MANAGER);

  const goBack = () => {
    raProps.history.goBack();
  };

  const onSubmit = async (values: any) => {
    try {
      await saveNotificationSettings(stores.auth.token as string, { settings: values, companyId }, getApiRoutes().companyAdmin.saveNotificationSettings());
      raProps.history.goBack();
    } catch (error) {
      stores.ui.setOverlay(<ErrorMessage>{__((error as any)?.toLocalizedString() ?? `${error}`)}</ErrorMessage>);
    }
  };

  return (
    <Card>
      <Header goBack={goBack} title={`${__('Notification settings')} (${companyId})`} />
      <CardContent>
        <Formik
          initialValues={settings || defaultNotificationSettingValues[company?.type || CompanyTypes.EXT]}
          validationSchema={NotificationSettingsFormSchema}
          enableReinitialize
          validateOnMount
          onSubmit={async (values: NotificationSettingsFormValues, actions: FormikActions<NotificationSettingsFormValues>) => {
            await onSubmit(values);
            actions.setSubmitting(false);
          }}
        >
          {({ isSubmitting, isValid }: FormikProps<NotificationSettingsFormValues>) => (
            <Form>
              <NotificationSettingsForm
                readonly={!isAllowedToEdit}
                allSettingValues={getAllNotificationSettingValues(activeNotificationSettingsWithTwilio)}
                activeSettingValues={activeNotificationSettingsWithTwilio}
              />
              {isAllowedToEdit && (
                <ConfirmButtons>
                  <ColumnLayout ratio="1fr 1fr" gap="8px">

                    <Button
                      type="button"
                      info
                      disabled={isSubmitting}
                      onClick={() => raProps.history.goBack()}
                      isLoading={isSubmitting}
                      title={__('Cancel')}
                    />

                    <Button
                      disabled={isSubmitting || !isValid}
                      type="submit"
                      ctaBorder
                      isLoading={isSubmitting}
                      title={__('Save')}
                    />
                  </ColumnLayout>
                </ConfirmButtons>
              )}
            </Form>
          )}

        </Formik>
      </CardContent>
    </Card>
  );
};

const mapEditStateToProps = (state: any) => ({
  resources: getResources(state),
});

export default connect(mapEditStateToProps, {
  showNotification: showNotificationAction,
})(withRouter(NotificationSettingsEdit));
