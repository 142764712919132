import { UserRole, getPasswordRegex } from '@adac/core-model';
import { passwordErrorMessage, concatSchemas } from '@adac/core-view';
import * as yup from 'yup';
import { SignInChannelSchema } from './UserSignInChannelForm';

export const UserFormSchema = yup.object().shape({
  name: yup.string().required(),
  username: yup.string().when('role', {
    is: UserRole.API,
    then: schema => schema.nullable(),
    otherwise: schema => schema.required(),
  }),
  password: yup.string().when('role', {
    is: UserRole.API,
    then: schema => schema.nullable(),
    otherwise: schema => schema.oneOf([yup.ref('confirmPassword')]).matches(getPasswordRegex(), passwordErrorMessage),
  }),
  confirmPassword: yup.string().when('role', {
    is: UserRole.API,
    then: schema => schema.nullable(),
    otherwise: schema => schema.oneOf([yup.ref('password')]),
  }),
});

export const CreateUserFormSchema = concatSchemas(UserFormSchema, SignInChannelSchema).shape({
  role: yup.string().required(),
});
